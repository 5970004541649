import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { signMessage } from "@/helpers/sign-message";
import { shorten } from "@/helpers/utils";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import ENS from "ethereum-ens";

export const sendUtilities = async (destination, items) => {
  try {
    const ids = items.map((item) => item.id);
    const amounts = items.map((item) => item.count);
    let wallet = destination;
    const isENS = !wallet.startsWith("0x") || wallet.endsWith(".eth");
    if (isENS) {
      const { network } = Config;
      const ens = new ENS(network.rpcUrl.replace("sepolia", "mainnet"));
      try {
        wallet = await ens.resolver(wallet).addr();
      } catch (err) {
        console.log("Fail resolving ENS", err);
      }
    }
    const signature = await signMessage("I'm sending off-chain Utilities");
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/wallet/utility-transfer",
      method: "POST",
      data: {
        sender: useWeb3Store().collectionWallet,
        recipient: wallet,
        ids,
        amounts,
        signer: useWeb3Store().walletAddress,
        signature,
        network: Config.network.name,
      },
    });
    useToast().success({
      title: `You successfully transferred utilit${
        items.length > 1 ? "ies" : "y"
      } to ${shorten(wallet, 4, 4)}`,
    });
    useWalletCollectionStore().fetchCollection(true);
  } catch (err) {
    captureError(err);
  }
};
